@font-face {
    font-family: 'Candara';
    src: local('Candara'), url("./Fonts/Candara.ttf");
}

@font-face {
    font-family: 'Material_Sans';
    src: local('Material_Sans'), url("./Fonts/Material_Sans.ttf");
}

@font-face {
    font-family: 'Calibri-bold';
    src: local('Calibri-bold'), url("./Fonts/Calibri-bold.ttf");
}

@font-face {
    font-family: 'Calibri-regular';
    src: local('Calibri-regular'), url("./Fonts/Calibri-regular.ttf");
}

*{
    -ms-overflow-style: none;
    -webkit-tap-highlight-color: transparent;
}
::-webkit-scrollbar {
    display: none;
}


